import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import NavigationButtons from "../components/NavigationButtons"
import TextPage from "../pagesLayouts/TextPage"
import { WordpressPageQuery } from "../types/schema"

const navigation = {
  left: {
    href: "/team",
    text: "ZESPÓŁ",
  },
  right: {
    href: "/timetable",
    text: "REZERWUJ",
  },
}

export default function ClassesPl() {
  const query = graphql`
    query {
      wpPage(language: { slug: { eq: "pl" } }, slug: { eq: "classes" }) {
        ...WordpressPageFragment
      }
    }
  `
  const data = useStaticQuery<WordpressPageQuery>(query)

  return (
    <TextPage data={data} language="en">
      <NavigationButtons left={navigation.left} right={navigation.right} />
    </TextPage>
  )
}
